@font-face {
  font-family: 'icomoon';
  src: url(../fonts/icomoon.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-baretapes .path1:before {
  content: "\e900";
  color: rgb(244, 244, 244);
  opacity: 0.3;
}

.icon-baretapes .path2:before {
  content: "\e901";
  margin-left: -0.046875em;
  color: rgb(244, 244, 244);
}

.icon-baretapes .path3:before {
  content: "\e902";
  margin-left: -0.046875em;
  color: rgb(244, 244, 244);
  opacity: 0.3;
}

.icon-baretapes .path4:before {
  content: "\e903";
  margin-left: -0.046875em;
  color: rgb(244, 244, 244);
}

.icon-baretapes .path5:before {
  content: "\e904";
  margin-left: -0.046875em;
  color: rgb(244, 244, 244);
  opacity: 0.3;
}

.icon-baretapes .path6:before {
  content: "\e905";
  margin-left: -0.046875em;
  color: rgb(244, 244, 244);
}

.icon-baretapes .path7:before {
  content: "\e906";
  margin-left: -0.046875em;
  color: rgb(244, 244, 244);
  opacity: 0.3;
}

.icon-baretapes .path8:before {
  content: "\e907";
  margin-left: -0.046875em;
  color: rgb(244, 244, 244);
}

.icon-line:before {
  content: "\e908";
}

.icon-carbon_location-filled:before {
  content: "\e90b";
  color: #f4f4f4;
}

.icon-ic_round-done:before {
  content: "\e90c";
  color: #214be3;
}

.icon-ic_round-phone_cta:before {
  content: "\e90d";
  color: inherit;
}

.icon-ic_round-phone:before {
  content: "\e90e";
  color: #f4f4f4;
}

.icon-icn_cta_hovered:before {
  content: "\e910";
  color: #f4f4f4;
}

.icon-icn_google:before {
  content: "\e911";
  color: inherit;
}

.icon-icn_linkdin:before {
  content: "\e912";
  color: inherit;
}

.icon-icn_phone:before {
  content: "\e913";
  color: #214be3;
}

.icon-medal-solid:before {
  content: "\e914";
  color: #214be3;
}

.icon-thumbs-up-solid:before {
  content: "\e915";
  color: #214be3;
}
