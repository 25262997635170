/* node_modules */
@import "../node_modules/slick-carousel/slick/slick.css";
@import "../node_modules/slick-carousel/slick/slick-theme.css";
@import "./icon";

$blue: #2748be;
$primary: #214be3;
$white: #f4f4f4;
$black: #02071c;
$white-description: rgba(244, 244, 244, 0.8);
$grey: #4e5160;
$border-blue: #e9f0f9;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px,
) !default;

$navbar-light-icon-color: rgba($white, 0.75) !default;

@import "../node_modules/bootstrap/scss/bootstrap";

@font-face {
  font-family: Gilroy-Regular;
  src: url(./../fonts/Gilroy-Regular.ttf);
}

@font-face {
  font-family: Gilroy-Light;
  src: url(./../fonts/Gilroy-Light.ttf);
}

@font-face {
  font-family: Gilroy-Medium;
  src: url(./../fonts/Gilroy-Medium.ttf);
}
@font-face {
  font-family: Gilroy-Thin;
  src: url(./../fonts/Gilroy-Thin.ttf);
}

@font-face {
  font-family: DarkerGrotesque-Medium;
  src: url(./../fonts/DarkerGrotesque-Medium.ttf);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-5px);
  }
}

@keyframes fadeSlideUp {
  0% {
    transform: translateY(100%);
  }
  20% {
    transform: translateY(-50%);
  }
  80% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(-150%);
  }
}

:root {
  --bs-body-font-family: Gilroy-Light;
  --bs-body-color: #{$black};
  --s: 8;
  --h: 49;
  --speed: 0.8s;
}

.navbar {
  --bs-navbar-toggler-border-color: #{$white};
}

h1,
h2,
h3 {
  --bs-body-font-family: DarkerGrotesque-Medium;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 35px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 16px;
}

h5 {
  color: $grey;
  font-size: 14px;
  letter-spacing: 0.64px;
}

h6 {
  font-size: 16px;
}

h1 + p {
  margin: 16px 0 24px;
}

h5 + h2 {
  margin-top: 13px;
  margin-bottom: 32px;
  line-height: 40px;
  letter-spacing: 0.7px;
}

h2 + h3 {
  margin: 64px 0 32px;
}

h4 + h3 {
  margin: 2px 0 8px;
}

.small {
  font-size: 14px;
  color: $black;
}

.icon {
  width: 8px;
  height: 8px;
  margin: 0.5em 5px;
}

.text-description {
  color: $white-description;
}

.text-justify {
  text-align: justify;
}

.bg-blue {
  background: linear-gradient(158deg, #2748be 4.69%, #12309b 85.69%);
  color: $white-description;

  h5,
  h2,
  hr {
    color: $white;
  }

  a:not(.text-description):not(.nav-link) {
    color: $white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

section > header h5 {
  color: $grey;
}

body {
  padding-top: 153px;
  background-color: white;
}

#entreprise {
  scroll-margin-top: 250px; // arbitraire
}

#entites {
  scroll-margin-top: 200px; // arbitraire
}

#methodologie,
#valeurs,
#president,
#projets,
#contact {
  scroll-margin-top: calc(82px); // hauteur header-top-container

  @include media-breakpoint-up(lg) {
    scroll-margin-top: 95px;
  }
}

body > header {
  border-bottom: 1px solid $border-blue;

  > * {
    padding: 26px 0;
  }

  .header-top-container {
    display: flex;
    @include media-breakpoint-up(md) {
      justify-content: space-between;
      align-items: center;
    }
  }

  .navbar {
    overflow-y: auto;
    &.responsive-open {
      max-height: calc(100vh - 82px);
      @include media-breakpoint-up(md) {
        height: auto;
      }
    }
  }

  .navbar-brand + .smart-links {
    display: flex;
    gap: 25px;
    font-size: 14px;
    color: $white;

    @include media-breakpoint-up(md) {
      margin-left: 89px;
    }
  }
}

[class^="icon-"].text-description,
a.text-description {
  color: $white-description;
  text-decoration: none;

  &:hover {
    color: $white;
    text-decoration: underline;
  }
}

.navbar-nav {
  --bs-nav-link-color: #{$white-description};
  --bs-nav-link-hover-color: #{$white};
  letter-spacing: 0.64px;
  font-family: Gilroy-Regular;
  font-size: 18px;

  gap: 25px;

  .nav-link:hover {
    font-family: Gilroy-Medium;
  }
}

.navbar {
  border-top: 1px solid rgba(244, 244, 244, 0.2);

  a.header-phone-number {
    color: $white-description;
    text-decoration: none !important;

    &:hover {
      color: $white;
    }
  }
}

footer {
  padding: 30px 0;

  .brand {
    margin-bottom: 64px;
  }

  .contact-info {
    display: flex;
    flex-direction: column;
    padding-bottom: 64px;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  p.contact {
    margin-bottom: 32px;
  }

  .info-table {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
    }

    div:nth-child(1) {
      width: 177px;
      margin-right: 80px;
    }
    div:nth-child(2) {
      min-width: 152px;
      margin-right: 110px;
    }
    div:nth-child(3) {
      width: 264px;
    }
  }

  .nav-wrapper {
    display: flex;
    flex-direction: column;

    .navbar-nav {
      flex-wrap: wrap;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      flex-wrap: wrap;
    }
    @include media-breakpoint-up(lg) {
      justify-content: space-between;
    }
  }

  .rs-contact {
    display: flex;
    gap: 32px;
    font-size: 32px;
    margin-top: 20px;

    @include media-breakpoint-up(md) {
      margin-top: unset;
    }
  }

  .navbar-nav {
    flex-direction: column;
    gap: 10px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  nav {
    border-top: 1px solid rgba(244, 244, 244, 0.2);
    padding-top: 30px;
  }
}

main {
  padding: 77px 0;
  font-size: 18px;
  line-height: 27px;
  overflow: hidden;

  > header {
    padding-top: 0;
    @include media-breakpoint-up(md) {
      padding-top: calc(162px - 77px);
      background: url(../img/svg_home.svg) no-repeat;
      background-position: right top;
      background-size: 50% 100%;
    }

    @include media-breakpoint-up(xxl) {
      background-size: unset;
    }
  }

  .title-animated {
    line-height: 49px;
    letter-spacing: 1.2px;
    display: flex;
    flex-wrap: wrap;

    &-list {
      display: flex;
      flex-direction: column;
      min-width: 380px;
      width: 100%;
      height: 49px;
      overflow-x: visible;
      overflow-y: hidden;
      position: relative;
    }

    &-item {
      color: red;
      opacity: 0;
      height: 49px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &.active {
        opacity: 1;
        animation: fadeSlideUp 1.2s infinite;
      }
    }

    @include media-breakpoint-up(xl) {
      white-space: nowrap;
      flex-wrap: nowrap;

      &-list {
        width: 380px;
        padding-left: 10px;
      }
    }
  }

  .contact-us {
    margin-bottom: 70px;
    padding: 11px 14px;
    font-size: 18px;
    line-height: 22px;
    font-family: DarkerGrotesque-Medium;
    display: flex;
    align-items: center;
    width: fit-content;

    @include media-breakpoint-up(md) {
      margin-bottom: 138px;
    }
  }

  .stats {
    display: flex;
    font-family: DarkerGrotesque-Medium;
    font-size: 24px;
    gap: 40px;

    .small {
      font-family: Gilroy-Light;
      font-size: 14px;
    }
  }

  .on-going {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    color: $primary;
    font-size: 16px;
    font-family: DarkerGrotesque-Medium;
    margin: 70px auto;

    @include media-breakpoint-up(md) {
      margin: 142px 0 144px;
    }

    .bounce {
      animation: bounce linear 2s infinite alternate;
    }
  }
}

.projets,
.story,
.president,
.valeurs {
  padding: calc(192px / 2) 0;
}

.carousel-btn-wrapper {
  display: flex;
  width: 90px;

  .slider-btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: url(../img/icn_arrow_default.svg) center no-repeat;

    &.next-btn {
      transform: rotate(180deg);
    }

    &:hover {
      background: url(../img/icn_arrow_hovered.svg) center no-repeat;
    }

    &.slick-disabled {
      background: url(../img/icn_arrow_disable.svg) center no-repeat;
    }
  }
}

.entites {
  padding-bottom: 124px;

  .intro-carousel {
    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: space-between;
    }

    .carousel-btn-wrapper {
      margin: 0 auto;
    }
  }

  #entitesCarousel {
    margin-top: 20px;
    @include media-breakpoint-up(lg) {
      margin-top: 64px;
    }
  }

  .carousel-inner {
    display: flex;
    flex-direction: row;
  }

  .card {
    height: 414px;
    width: 343px !important;
    border-radius: 0;
    background-color: transparent;
    border: none;

    .card-body {
      padding: 0;
      height: calc(414px - 199px);
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(lg) {
        height: auto;
      }
    }

    .card-img-top {
      height: 199px;
      width: 100%;
      border-radius: 0;
      background-color: transparent;
      border: none;

      &.smart-consulting {
        background: url(../img/cards/card_photo.jpg) no-repeat center;
        background-size: contain;

        &:hover {
          background-image: url(../img/cards/card1_hovered.png);
        }
      }

      &.smart-formation {
        background: url(../img/cards/card2_photo.png) no-repeat center;
        background-size: contain;

        &:hover {
          background-image: url(../img/cards/card2_hovered.png);
        }
      }
      &.smart-green {
        background: url(../img/cards/card3_photo.png) no-repeat center;
        background-size: contain;

        &:hover {
          background-image: url(../img/cards/card3_hovered.png);
        }
      }
      &.smart-business {
        background: url(../img/cards/card4_photo.png) no-repeat center;
        background-size: contain;

        &:hover {
          background-image: url(../img/cards/card4_hovered.png);
        }
      }
      &.smart-restructuring {
        background: url(../img/cards/card5_photo.png) no-repeat center;

        &:hover {
          background-image: url(../img/cards/card5_hovered.png);
        }
      }
      &.smart-solutions {
        background: url(../img/cards/card6_photo.png) no-repeat center;
        background-size: contain;

        &:hover {
          background-image: url(../img/cards/card6_hovered.png);
        }
      }
      &.smart-entrepreneur {
        background: url(../img/cards/card7_photo.png) no-repeat center;
        background-size: contain;

        &:hover {
          background-image: url(../img/cards/card7_hovered.png);
        }
      }
      &.smart-ai {
        background: url(../img/cards/card8_photo.png) no-repeat center;
        background-size: contain;

        &:hover {
          background-image: url(../img/cards/card8_hovered.png);
        }
      }

      .card-content {
        display: flex;
        flex-direction: column;
        height: 100%;

        @include media-breakpoint-up(lg) {
          overflow: hidden;
          height: 0;
          transition: height 0.2s ease-out;
        }
      }

      &:hover {
        .card-content {
          height: calc(414px - 199px - 45px);
        }
      }
    }

    .card-title {
      font-family: DarkerGrotesque-Medium;
      font-size: 24px;
      line-height: 26px;
      padding: 6px 17px 13px;
    }

    .card-text {
      padding: 0 17px 6px;
      flex-grow: 1;
    }

    .card-footer {
      padding: 17px 20px;
      border-top: 1px solid $white-description;
      color: $white-description;
    }

    .card-content {
      display: flex;
      flex-direction: column;
      height: 100%;

      @include media-breakpoint-up(lg) {
        overflow: hidden;
        height: 0;
        transition: height 0.2s ease-out;
      }
    }

    &:hover {
      .card-content {
        height: calc(414px - 199px - 45px);
      }
    }
  }
}

.methodologie {
  padding: 125px 0;
  p {
    margin-bottom: 2rem;
  }

  .article {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: space-between;
    }

    hr {
      @include media-breakpoint-up(lg) {
        width: 245px;
      }
    }
  }

  .steps {
    h3 {
      font-size: 16px;
      color: $white-description;
    }

    h3 + p {
      font-size: 24px;
      color: $white;
      margin-bottom: 0.5rem;
    }

    article {
      padding-left: 40px;
      position: relative;

      &::before {
        content: "";
        width: 30px;
        height: 30px;
        background: url(../img/list-style.svg) center no-repeat;
        display: block;
        position: absolute;
        left: -8px;
      }

      &:not(:last-of-type)::after {
        content: "";
        width: 1px;
        height: calc(100% + 2rem);
        display: block;
        background-color: $white-description;
        left: 7px;
        position: absolute;
        top: 15px;
      }
    }
  }
}

.valeurs {
  padding-top: 124px;

  .articles {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: space-between;
      gap: unset;

      article {
        width: 360px;
      }
    }
  }

  .humility::before,
  .agility::before,
  .professionnalisme::before {
    content: "";
    width: 64px;
    height: 46px;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    margin-right: 15px;
  }

  .agility::before {
    background-image: url(./../img/icn/icn_agilite.svg);
  }
  .humility::before {
    background-image: url(./../img/icn/icn_humilite.svg);
  }
  .professionnalisme::before {
    background-image: url(./../img/icn/icn_professionnalisme.svg);
  }
}

.president {
  .about {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 64px;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }

  .books {
    height: 555px;
    align-items: center;
    position: relative;
    margin-bottom: 70px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 192px;
      &::before {
        content: "";
        background-image: url(./../img/section_books.jpg);
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 50%;
      }
    }

    h2 {
      margin-bottom: 64px;
    }

    a {
      font-family: DarkerGrotesque-Medium;
      color: $grey;
      text-decoration: none;
      border-bottom: 1px solid $border-blue;
      padding: 15px 0;
      margin-bottom: 3px;
      font-size: 24px;
      &:hover {
        color: $primary;
      }
    }
  }

  .bio {
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      margin-bottom: 192px;
      flex-direction: row;
      align-items: center;
    }

    h4 {
      color: $grey;
    }

    .img-wrapper {
      width: 100%;
      margin-bottom: 70px;
      img {
        margin: 0 auto;
        display: block;
      }

      @include media-breakpoint-up(lg) {
        width: 370px;
        margin-right: 120px;
        margin-bottom: 0;
      }
    }
  }

  .story {
    padding-bottom: 0;
  }

  .stories-article {
    display: flex;
    flex-direction: column;
    gap: 64px 24px;
    margin-top: 64px;

    @include media-breakpoint-up(md) {
      flex-wrap: wrap;
      flex-direction: row;
    }

    article {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: calc((100% - (24px)) / 2);
      }
      @include media-breakpoint-up(lg) {
        width: calc((100% - (24px * 3)) / 4);
      }

      hr {
        color: $black;
        opacity: 1;
      }
    }
  }
}

.projets {
  .smart-stories {
    margin-top: 100px;

    @include media-breakpoint-up(md) {
      margin-top: 60px;
    }

    .carousel-btn-wrapper {
      right: calc(50% - 40px);
      position: absolute;
      top: -60px;

      @include media-breakpoint-up(lg) {
        right: 0;
      }
    }

    .carousel-item-wrapper {
      display: flex;
      flex-direction: column;

      img {
        width: 100%;
        height: auto;
      }

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        img {
          width: revert-layer;
          height: auto;
        }
      }

      background-color: white;
      align-items: center;
    }

    p + ul {
      margin: 30px 0;
      list-style: none;
      padding-left: 0;
    }

    li {
      padding-left: 2rem;
      color: $primary;
      background: url(../img/icn/ic_round-done.svg) no-repeat;
      background-position: left -2px;

      + li {
        margin-top: 8px;
      }
    }

    h3 {
      margin-bottom: 8px;
    }
  }
}

.partners {
  padding: calc(192px / 2) 0 calc(192px - 67px);

  .partner-logos {
    display: flex;
    align-items: center;
    margin-top: 64px;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    > div {
      height: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
